<template>
  <div class="sm:mx-28 mx-10 pt-24 sm:py-80 sm:h-36 sm:pt-[25rem]">
    <div class="text-md pb-4 sm:text-5xl font-thin">
      DREAM BIGGER, GROW FASTER
      <div
        class="inline-block sm:w-4 sm:h-4 h-1.5 w-1.5 bg-red-700 rounded-full"
      ></div>
    </div>
    <button
      @click="goContact"
      class="sm:rounded-full items-center sm:w-80 sm:h-[3.5rem] w-36 h-8 rounded-3xl sm:text-2xl text-xs text-white text-center sm:px-16 sm:mb-64 font-light inline-block bg-black"
    >
      Let's Get in Touch
    </button>
  </div>
</template>

<script setup lang="ts">
const router = useRouter();
function goContact() {
  router.push("/contact");
}
</script>
